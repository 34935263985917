<template>
  <v-app>
    <!--    DESCTOP APP BAR-->
    <v-app-bar
      class="d-none d-md-flex d-print-none"
      app
      color="primary"
      dark
    >
      <v-container class="py-0 fill-height">
        <v-btn text @click="allApplications">
          all Applications
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="logout">
          Logout
        </v-btn>
      </v-container>
    </v-app-bar>

    <!--    MOBILE APP BAR-->
    <v-app-bar app dark class="d-md-none d-print-none" color="primary">
      <!--      <v-app-bar-nav-icon  ><v-icon>mdi-menu</v-icon></v-app-bar-nav-icon>-->
      <v-app-bar-title>CRM Name here</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group>
            <v-list-item @click="allApplications">
              <v-list-item-icon>
                <v-icon>mdi-format-list-text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>All applications</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapMutations } from 'vuex';
import { logout } from '@/services/firebase';

export default {
  name: 'DashboardClientLayout',
  methods: {
    ...mapMutations(['SET_APPLICATION_DATA', 'SET_APPLICATION_MODEL']),
    allApplications() {
      if (this.$route.name !== 'ClientApplications') {
        this.$router.push({ name: 'ClientApplications' });
      }
    },
    logout() {
      logout();
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>

<style scoped>

</style>
