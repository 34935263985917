var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('v-app-bar', {
    staticClass: "d-none d-md-flex d-print-none",
    attrs: {
      "app": "",
      "color": "primary",
      "dark": ""
    }
  }, [_c('v-container', {
    staticClass: "py-0 fill-height"
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.allApplications
    }
  }, [_vm._v(" all Applications ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v(" Logout ")])], 1)], 1), _c('v-app-bar', {
    staticClass: "d-md-none d-print-none",
    attrs: {
      "app": "",
      "dark": "",
      "color": "primary"
    }
  }, [_c('v-app-bar-title', [_vm._v("CRM Name here")]), _c('v-spacer'), _c('v-menu', {
    attrs: {
      "bottom": "",
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-dots-vertical")])], 1)];
      }
    }])
  }, [_c('v-list', [_c('v-list-item-group', [_c('v-list-item', {
    on: {
      "click": _vm.allApplications
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-format-list-text")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("All applications")])], 1)], 1), _c('v-list-item', {
    on: {
      "click": _vm.logout
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-logout")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Logout")])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-main', [_c('v-container', [_c('router-view')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }